import * as React from "react";
import { graphql } from "gatsby";
import "@fontsource/red-hat-display";
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";
import logo from "/src/images/iso_color.png";
import Problem from "../components/about/problem";
import Solution from "../components/about/solution";
import Mission from "../components/about/mission";
import Team from "../components/about/team";
import Map from "../components/about/map";
import Awards from "../components/about/awards";
import "../static/mainPage.css";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import Subscribe from "../components/misc/check_list";
import { StaticImage } from "gatsby-plugin-image";

let cs_theme = createTheme({
  palette: {
    primary: {
      main: "#FFA100",
    },
    secondary: {
      main: "#00C5B7",
    },
    white: "#FFFFFF",
    black: "#202026",
    purple: "#8F4FFF",
  },
  typography: {
    fontFamily: ["Red Hat Display"].join(","),
  },
});
cs_theme = responsiveFontSizes(cs_theme);

const IndexPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  let header;
  header = (
    <StaticImage
      src="../images/iso_color.png"
      alt="Logo"
      loading="eager"
      formats={["auto", "webp", "avif"]}
    />
  );
  return (
    <ThemeProvider theme={cs_theme}>
      <Layout location={location} title={siteTitle}>
        <Seo title="About | Custom Surgical" />
        <Subscribe />
        <div className="image-container">
          <StaticImage
            src="../images/team.jpg"
            alt="Main Image"
            loading="eager"
            placeholder="blurred"
            formats={["auto", "webp", "avif"]}
            quality={100}
            style={{
              objectFit: "cover",
              aspectRatio: "2 / 1",
              width: "100%",
              height: "auto",
              height: "80vh",
              backgroundRepeat: "no-repeat",
              backgroundPositionX: "center",
              backgroundSize: "cover",
              width: "100%",
            }}
          />{" "}
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.4)",
            }}
          ></div>
          <div className="text-overlay">
            <h1 className="text_over">
              The{" "}
              <span style={{ color: "#FFA100", marginLeft: "5px" }}>
                {" "}
                One-Stop Shop{" "}
              </span>
              <div style={{ marginTop: "1rem" }}>for Digitalization</div>
            </h1>
          </div>
        </div>

        <Problem />
        <Solution />
        <Mission />
        <Map />

        <Awards />
        <Team />
      </Layout>
    </ThemeProvider>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
